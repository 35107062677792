import { MdOutlineClose } from "react-icons/md";
import styles from './modalForms/modalForm.module.css'

function CloseButton({ handleClick, target }) {
    return (
        <MdOutlineClose
            style={{
                position: "absolute",
                right: ".5rem",
                top: ".5rem",
                cursor: "pointer"
            }}
            size={30}
            onClick={(e) => { document.querySelector(`.${target}`).classList.add(styles.hideFormulario); setTimeout(() => handleClick(false), 200) }}
            onMouseDown={(e) => { e.target.style.transform = 'scale(90%)'; }}
        />
    );
}

export default CloseButton;