import React from 'react'
import { useForm } from '@felte/react'
import { MdError } from "react-icons/md";
import styles from './users-manager.module.css'

export const AddUserForm = ({ addUser }) => {

    const { form: createForm, errors } = useForm({
        onSubmit: (values) => addUser(values),
        validate: (values) => {
            const errors = {};
            const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
            if (!values.name || values.name.length < 5)
                errors.name = 'El nombre completo debe estar compuesto de 5 carácteres mínimo'
            if (!values.username || values.username.length < 5)
                errors.username = 'El nombre de usuario debe estar compuesto de 5 carácteres mínimo'
            if (!values.email || !values.email.match(regex))
                errors.email = 'El email debe ser de la forma ejemplo@ejemplo.com'
            if (!values.password)
                errors.password = 'La contraseña es obligatoria.'
            if (values.password !== values.password_confirmation)
                errors.password = 'Las contraseñas deben coincidir.'

            return errors;
        }
    })

    return (
        <form ref={createForm} className={styles.formulario}>
            <div>
                <label>Nombre completo:</label>
                <input type="text" name="name" required />
                {errors('name') && <span className="text-sm text-red-500"><MdError style={{ display: 'inline-block' }} /> {errors('name')}</span>}
            </div>
            <div>
                <label>Nombre de usuario:</label>
                <input type="text" name="username" required />
                {errors('username') && <span className="text-sm text-red-500"> <MdError style={{ display: 'inline-block' }} /> {errors('username')}</span>}
            </div>
            <div>
                <label>Email de contacto:</label>
                <input type="email" name="email" required />
                {errors('email') && <span className="text-sm text-red-500"> <MdError style={{ display: 'inline-block' }} /> {errors('email')}</span>}
            </div>
            <div>
                <label>Contraseña:</label>
                <input type="password" name="password" required />
                {errors('password') && <span className="text-sm text-red-500"><MdError style={{ display: 'inline-block' }} /> {errors('password')}</span>}
            </div>
            <div>
                <label>Confirmar contraseña:</label>
                <input type="password" name="password_confirmation" required />
            </div>
            <div>
                <label>Asignar rol:</label>
                <select name="admin" defaultValue='0'>
                    <option value="0">Usuario</option>
                    <option value="1">Administrador</option>
                </select>
            </div>
            <button type='submit' className='mi-boton self-end guardar'>Crear</button>
        </form>
    )
}
