import { DateTime } from "luxon";
import { useState } from "react"
import { useContext } from "react";
import { MessageContext } from "../contexts/MessageContext";

export const useForm = () => {
  const [info, setInfo] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const { setError } = useContext(MessageContext);

  const showForm = (cell) => {
    const now = DateTime.local();
    const endCell = DateTime.fromSQL(`${cell.fecha} ${cell.hora_inicio}`)
    if (cell.libre && now > endCell) {
      setError('No puedes crear reservas en el pasado.')
      setTimeout(() => setError(null), [5000])
      return;
    }
    setIsEditing(true);
    setInfo(cell);
    setCanEdit(!(now > endCell))
  };

  return { info, setInfo, isEditing, setIsEditing, showForm, canEdit };
}