import React, { useContext, useEffect, useState } from 'react'
import { TituloPagina } from '../../TituloPagina'
import { MessageContext } from '../../../contexts/MessageContext';
import { createSpace, activeSpace, getSpaces } from '../../../fetching/AdminAPI';
import { useForm } from '@felte/react';
import { Spinner } from '../../Spinner';
import ActiveSwitch from '../../ActiveSwitch';

export const Aulas = () => {
    const { setError, setSuccess } = useContext(MessageContext);
    const [loading, setLoading] = useState(true);
    const [array, setArray] = useState({});


    const { form } = useForm({
        onSubmit: (values) => {
            setLoading(true)
            createSpace(values)
                .then(res => {
                    fetchData()
                    setSuccess('Espacio guardado con éxito.')
                    setTimeout(() => setSuccess(null), 5000)
                })
                .catch(err => {
                    setError(err.response.data.message)
                    setTimeout(() => setError(null), 5000)
                })
                .finally(() => setLoading(false))
        }
    })

    const [data, setData] = useState(null);
    const fetchData = () => {
        getSpaces()
            .then(data => setData(data))
            .catch((err) => {
                setError(err.response.data.message)
                setTimeout(() => setError(null), 5000)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData();
    }, [])

    const activeEspacio = (id) => {
        activeSpace(id, !array[id])
            .then(res => {
                array[id] = !array[id];
            })
            .catch(err => {
                alert('Ha ocurrido un error');
            })
            .finally(() => fetchData())
    }
    return (
        <main className='p-[.5rem]'>
            <TituloPagina texto={'Aulas y espacios'} />
            <main className='flex-col-center gap-2'>
                <form ref={form} className='flex flex-col w-2/4'>
                    <div className='flex flex-col gap-1'>
                        <label htmlFor="nombre" className='text-slate-500'>Nombre del aula:</label>
                        <input type="text" name="nombre" id="nombre" required />
                    </div>
                    <button type="submit" className='mi-boton bg-emerald-500 hover:bg-emerald-600 self-end'>Guardar</button>
                </form>

                <div className='w-2/4'>
                    <h4 className='text-2xl'>Listado de aulas/espacios:</h4>
                    {loading ? <Spinner /> :
                        (<div className='flex flex-col gap-2 bg-slate-100 p-2'>
                            <div className='grid grid-cols-2 font-bold'>
                                <h1>Nombre</h1>
                                <h1 >Activo</h1>
                            </div>
                            {data && data.map(a => {
                                array[a.id] = a.activo;
                                return (<div className='font-raleway grid grid-cols-2 bg-white gap-1 py-1 px-2 border-b items-center my-1'>
                                    <p className='font-bold'>#{a.nombre}</p>
                                    <ActiveSwitch model={a} handleActive={activeEspacio} />
                                </div>)
                            }
                            )}
                        </div>)}
                </div>
            </main>
        </main>
    )
}
