import React, { useEffect, useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaTrashCan } from "react-icons/fa6";
import { deleteReserva, getReserva, updateReserva } from "../../fetching/AdminAPI";
import { useContext } from "react";
import { MessageContext } from "../../contexts/MessageContext";
import { SelectHourOptions } from "../calendar/SelectHourOptions";
import { Spinner } from '../Spinner'
import { UserPicker } from "../admin/UserPicker";
import CloseButton from "../CloseButton";
import { useForm } from "@felte/react";
import styles from './modalForm.module.css'
import { FormularioParent } from "./FormularioParent";


const { DateTime } = require("luxon");

function AdminEditReserva({ cellInfo, setIsEditingReserva, data: weekData, fetchData, canEdit }) {
  const [loading, setLoading] = useState(true);
  const [reservaInfo, setReservaInfo] = useState(null);
  const { setError, setSuccess } = useContext(MessageContext);

  const { form, setData, setInitialValues, reset } = useForm({
    onSubmit: (values) => {
      setLoading(true)
      updateReserva(cellInfo.id, values)
        .then(res => {
          setSuccess(res.data.message)
          fetchData()
          setIsEditingReserva(false)
          setTimeout(() => setSuccess(null), [5000])
        })
        .catch(err => {
          console.log(err.response.data.message);
          setError(err.response.data.message)
          setTimeout(setError(null), 5000);
        })
        .finally(() => setLoading(false))
    }
  })

  useEffect(() => {
    getReserva(cellInfo.id, cellInfo.fecha, cellInfo.sesion)
      .then(i => {
        setReservaInfo(i);
        setInitialValues({ user_id: i.user_id, descripcion: i.descripcion })
        reset()
      })
      .catch(err => {
        setError(err.response.data.message)
        setTimeout(() => setError(null), 5000)
      })
      .finally(() => setLoading(false))
  }, [])


  const deleteR = () => {
    setLoading(true)
    deleteReserva(cellInfo.id, reservaInfo.user_id)
      .then((res) => {
        fetchData();
        setIsEditingReserva(false);
        setSuccess("Se ha eliminado la reserva correctamente.");
        setTimeout(() => setSuccess(null), 5000);
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.message);
        setTimeout(() => setError(null), 5000);
      })
      .finally(()=>setLoading(false))
  }


  return (
    <FormularioParent>
      {loading ? <Spinner h={80} w={80} /> : null}
      <CloseButton handleClick={setIsEditingReserva} target={styles.formulario} />
      <div className={styles.header}>
        {reservaInfo && <h1 className="text-orange-400 inline">
          {DateTime.fromSQL(
            cellInfo.fecha + " " + reservaInfo.hora_inicio
          ).toLocaleString({
            weekday: "short",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })}{" "}
          /{" "}
          {DateTime.fromSQL(
            cellInfo.fecha + " " + reservaInfo.hora_fin
          ).toLocaleString({
            weekday: "short",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </h1>}
        <small className="flex items-center gap-2">
          <FontAwesomeIcon icon={faDoorOpen} />
          {reservaInfo ? reservaInfo.espacio : ''}
        </small>
      </div>

      {canEdit && <button type="button" className="mi-boton bg-red-600 hover:bg-red-700 w-11/12 mt-2 mx-auto"
        onClick={(e) => {
          if (window.confirm("Estás seguro de eliminar la reserva"))
            deleteR()
        }}
      >Eliminar reserva <FaTrashCan style={{ display: "inline" }} /></button>}

      <form id="admin-edit-reserva" ref={form} className="flex flex-col" >
        <div className="flex justify-around items-end">
          <div className="flex gap-4 items-center">
            <AiOutlineClockCircle />
            <span className="self-end">Desde:</span>
            <input type="time" value={reservaInfo ? DateTime.fromSQL(reservaInfo.hora_inicio).toFormat('HH:mm') : ''} readOnly />
          </div>
          <div className="flex items-end gap-4">
            <span>Hasta:</span>
            {reservaInfo && <SelectHourOptions data={weekData} cellInfo={cellInfo} fin={reservaInfo.sesion_fin} />}
          </div>
        </div>
        {reservaInfo && <UserPicker setData={setData} u={{ name: reservaInfo.name, id: reservaInfo.user_id }} />}
        <span className="flex items-center gap-2">
          <IoMdInformationCircle />
          Motivo de la reserva:
        </span>
        <textarea
          required
          minLength={15}
          name="descripcion"
          id=""
          cols="20"
          rows="3"
        ></textarea>
        {canEdit && <button className="guardar mi-boton">Editar y guardar</button>}
      </form>
    </FormularioParent>
  )
}

export default AdminEditReserva;

