import { BiWrench } from "react-icons/bi";
import { FiUsers } from "react-icons/fi";
import { RiCodepenLine } from "react-icons/ri";
import { BiSolidParty } from "react-icons/bi";
import { MdRoomPreferences } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { getConfig } from "../fetching/AdminAPI";
function AdminPage() {
    const [exists, setExists] = useState(null)

    useEffect(() => {
        getConfig()
            .then(data => {
                setExists(data.exists)
            })
            .catch(err => {
                setExists(err.response.data.exists)
            })
    }, [])

    return (
        <main className='p-2 gap-4 border flex-col-center justify-start h-[100vh]'>
            <h1 className="text-3xl font-rocondensed">Opciones de administrador</h1>
            <div className="grid grid-cols-3 grid-rows-2 gap-5 w-3/4">
                <Link to='config'>
                    <div className='admin-option'>
                        <BiWrench size={70} />
                        <h1>Configurar horario</h1>
                    </div>
                </Link>
                <Link to='users'>
                    <div className='admin-option'>
                        <FiUsers size={70} />
                        <h1>Gestionar usuarios</h1>
                    </div>
                </Link>
                <Link to={exists ? 'reservas' : ''}>
                    <div className={(!exists ? 'admin-option-info' : '') + ' admin-option'}>
                        <RiCodepenLine size={70} />
                        <h1>Gestión de reservas</h1>
                    </div>
                </Link>
                <Link to='festivos'>
                    <div className='admin-option'>
                        <BiSolidParty size={70} />
                        <h1>Festivos</h1>
                    </div>
                </Link>
                <Link to='aulas'>
                    <div className='admin-option'>
                        <MdRoomPreferences size={70} />
                        <h1>Aulas y espacios</h1>
                    </div>
                </Link>
            </div>
        </main>
    );
}

export default AdminPage;