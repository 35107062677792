import React from 'react';
import { Spinner } from '../Spinner';

function BookingInfo({ data, error, isLoading, children }) {

    if (isLoading)
        return <Spinner h={80} w={80} />
    if (error) {
        console.log(error);
        return <h1>Error</h1>
    }
    if (data) {
        return (
            <div className='book-info col-start-2 p-1 border-e-[1px] border-b-[1px] border-cyan-900 rounded-br-sm bg-cyan-700' style={{ gridTemplateColumns: `repeat(${data.info.length}, 1fr)` }}>
                {children}
            </div>
        )
    }
}

export default BookingInfo;