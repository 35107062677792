import React from 'react';

function ButtonWeekPage({ text, handleClick, avanza = true , retrocede = true}) {
    const icon = text;
    return (
        <span className='flex items-center gap-1'>
            <button
                className='px-2 py-1 bg-stone-500 text-white rounded-sm focus-within:bg-rose-500 focus-within:scale-90 material-symbols-outlined'
                onClick={() => { if (avanza) handleClick() }}
                disabled={!avanza}>
                {icon}
            </button>
        </span>
    );
}

export default ButtonWeekPage;