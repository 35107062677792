import { useEffect, useMemo, useRef, useState } from "react";
import { getSpaces } from "../../fetching/API";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import { Spinner } from "../Spinner";


function SpaceContainer({ setSpace }) {

  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [dropMenu, setDropMenu] = useState(false);

  const css = 'cursor-pointer inline-block rounded bg-neutral-50 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-neutral-800 shadow-[0_4px_9px_-4px_#cbcbcb] transition duration-150 ease-in-out hover:bg-neutral-100 hover:shadow-[0_8px_9px_-4px_rgba(203,203,203,0.3),0_4px_18px_0_rgba(203,203,203,0.2)] focus:bg-neutral-100 focus:shadow-[0_8px_9px_-4px_rgba(203,203,203,0.3),0_4px_18px_0_rgba(203,203,203,0.2)] focus:outline-none focus:ring-0 active:bg-neutral-200 active:shadow-[0_8px_9px_-4px_rgba(203,203,203,0.3),0_4px_18px_0_rgba(203,203,203,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(251,251,251,0.3)] dark:hover:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.1),0_4px_18px_0_rgba(251,251,251,0.05)] dark:focus:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.1),0_4px_18px_0_rgba(251,251,251,0.05)] dark:active:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.1),0_4px_18px_0_rgba(251,251,251,0.05)]'

  const handleClick = (e, space) => {
    setSpace(space);
  }

  useEffect(() => {
    setLoading(true);
    getSpaces()
      .then((data) => setData(data))
      .catch((err) => setError(err))
      .finally(() => setLoading(false))
  }, []);

  if (loading)
    return <Spinner w={80} h={80} />;

  if (error) return <h1>{error.message}</h1>;

  if (data) {
    return (
      <div className="flex flex-col bg-slate-100 rounded-t-xl border items-center justify-center gap-2 p-1 w-[99%]" id="spaces-container">
        <div className="flex gap-2 items-center cursor-pointer" onClick={() => setDropMenu(prev => !prev)} >
          <h1 className="text-3xl font-rocondensed ">Elige una clase para ver las horas disponibles</h1>
          {dropMenu ? <IoIosArrowDropupCircle size={36} /> : <IoIosArrowDropdownCircle size={36}  />}
        </div>
        <div className={(dropMenu ? "h-auto mb-2 " : "h-0") + " grid grid-cols-6 gap-3 px-3 rounded-sm w-full transition-all"}>
          {data.map((space) => {
            return (
              <div
                key={`aula-${space.id}`}
                className={(dropMenu ? `block py-2 ${css}` : 'h-0 p-0 overflow-hidden ')}
                onClick={(e) => {
                  handleClick(e, space);
                }}
              >
                <h2 className="text-md">{space.nombre}</h2>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default SpaceContainer;
