import React from 'react'

export const MyCell = ({ obj, handleClick }) => {
    const style = { gridRow: `span ${obj.span || 1}` };

    return (
        <div className="cell bg-emerald-500 cursor-pointer" style={style} onClick={(e) => { handleClick(e, obj) }}>
                <p className="text-sm">Editar ({obj.name})</p>
        </div>
    )
}
