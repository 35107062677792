import { IoMdInformationCircle } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useForm } from "@felte/react";
import { createReserva } from "../../fetching/API";
import { useContext, useState } from "react";
import { SelectHourOptions } from "../calendar/SelectHourOptions";
import { MessageContext } from '../../contexts/MessageContext'
import styles from './modalForm.module.css'
import CloseButton from "../CloseButton";
import { Spinner } from "../Spinner";
import { FormularioParent } from "./FormularioParent";
const { DateTime } = require("luxon");

function FormularioNewReserva({ cellInfo, setIsMakingReserva, space, fetchData, data }) {
  const [loading, setLoading] = useState(false);
  const { setError, setSuccess } = useContext(MessageContext);
  const { form: newForm } = useForm({
    onSubmit: (values) => {
      const formData = {
        sesion_inicio: cellInfo.sesion,
        sesion_fin: values.sesion_fin,
        descripcion: values.descripcion,
        espacio_id: space.id,
        fecha: cellInfo.fecha
      };
      setLoading(true);
      createReserva(JSON.stringify(formData))
        .then((res) => {
          if (res.status === 201) {
            fetchData();
            setIsMakingReserva(false);
            setSuccess('Reserva creada correctamente')
            setTimeout(() => {
              setSuccess(null);
            }, 5000)
          }
        })
        .catch((error) => {
          if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            setError(error.response.data.message);
            console.log(error.response.data.message);
          } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            setError('No se ha recibido respuesta del servidor')
          } else {
            // Algo paso al preparar la petición que lanzo un Error
            setError('Error: ' + error.message)
          }
          setTimeout(() => { setError(null) }, 5000)
        })
        .finally(() => setLoading(false));
    },
  });

  return (
    <FormularioParent>
      <CloseButton handleClick={setIsMakingReserva} target={styles.formulario} />
      {loading && <Spinner h={80} w={80} />}
      <div className="header">
        <h1 className="text-orange-400">
          {DateTime.fromSQL(cellInfo.fecha + " " + cellInfo.hora_inicio).toLocaleString({
            weekday: "short",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </h1>
        <small className="flex items-center gap-2">
          <FontAwesomeIcon icon={faDoorOpen} />
          {space.nombre}
        </small>
      </div>
      <form ref={newForm}>
        <div className="flex justify-around items-end">
          <div className="flex gap-4 items-center">
            <AiOutlineClockCircle />
            <span className="self-end">Desde:</span>
            <input type="time" value={DateTime.fromSQL(cellInfo.hora_inicio).toFormat('HH:mm')} />
          </div>
          <div className="flex items-end gap-4">
            <span>Hasta:</span>
            <SelectHourOptions data={data} cellInfo={cellInfo} />
          </div>
        </div>
        <span className="flex items-center gap-2">
          <IoMdInformationCircle />
          Motivo de la reserva:
        </span>
        <textarea
          required
          minLength={15}
          name="descripcion"
          id=""
          cols="20"
          rows="3"
        ></textarea>
        <button className="guardar mi-boton">Guardar</button>
      </form>
    </FormularioParent>
  );
}

export default FormularioNewReserva;
