import React, { useEffect, useState } from "react";
import { useForm } from "@felte/react";
import styles from "./config-form.module.css";
import { createHorario, deleteConfig, getConfig } from "../../../fetching/AdminAPI";
import FormFieldset from "../../calendar/FormFieldset";
import { useContext } from "react";
import { MessageContext } from '../../../contexts/MessageContext'
import { TituloPagina } from "../../TituloPagina";
import { Spinner } from "../../Spinner";
import { useNavigate } from "react-router-dom";


export function FormularioConfiguracion() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const [exists, setExists] = useState(false);
  const { setError, setSuccess } = useContext(MessageContext);
  const { form, unsetField, addField, data } = useForm({
    onSubmit: (values) => {
      setLoading(true)
      createHorario(values)
        .then(res => {
          setSuccess(res.data.message)
          setTimeout(() => setSuccess(null), [5000])
          navigate("/");
        })
        .catch(err => {
          setError(err.response.data.message)
          setTimeout(() => setError(null), [5000])
        })
        .finally(() => setLoading(false))
    }
  });

  const descansosArray = data("descansos") || [];
  const festivosArray = data('festivos') || [];

  useEffect(() => {
    setLoading(true)
    getConfig()
      .then(data => {
        setExists(false)
      })
      .catch(err => {
        setExists(true)
        setError(err.response.data.message);
        setTimeout(() => setError(null), [5000])
      })
      .finally(() => setLoading(false))
  }, []);


  return (
    <main className="p-[.5rem] relative">
      {loading && <Spinner h={80} w={80} />}
      <TituloPagina texto={'Formulario de configuración de curso'} />
      {exists ? <div>
        <form onSubmit={(e) => {
          e.preventDefault()
          setLoading(true)
          deleteConfig()
            .then((res) => {
              setSuccess('Se ha reiniciado la aplicación')
              setTimeout(() => setSuccess(null), [5000])
              navigate('/')
            })
            .catch(error => {
              setError(error.response.data.message)
              setTimeout(() => setError(null), [5000])
            })
            .finally(() => setLoading(false))
        }}>
          <button type="submit" className="bg-red-500 mi-boton hover:bg-red-600"
          >Reiniciar</button>
        </form>
      </div> : null}
      <form ref={form} action="/form/validate" method="post" className="flex flex-col items-start w-3/4 gap-5 px-4 py-2 mx-auto">
        {exists ?
          <button type="button" disabled className="self-end mi-boton bg-slate-400 hover:bg-slate-60">Iniciar curso</button>
          :
          <button type="submit" className="self-end bg-red-500 mi-boton hover:bg-red-600">Iniciar Curso</button>}
        <FormFieldset css="flex-col gap-5" legend="Intervalo horario">
          <div className="flex items-center justify-start gap-24">
            <div className={styles.timeParent}>
              <span>Hora inicio:</span>
              <input type="time" name="horaInicio" />
            </div>
            <div className={styles.timeParent}>
              <span>Hora fin:</span>
              <input type="time" name="horaFin" />
            </div>
          </div>
          <div className={styles.timeParent}>
            <p>Duración cada hora:</p>
            <input type="number" name="duracionSesion" className="time" />
            <span className="text-gray-500">min</span>
          </div>
        </FormFieldset>

        <div className="w-full flex-col-center">
          <button type="button" className="self-end bg-red-400 mi-boton hover:bg-red-500"
            onClick={() => addField("descansos", { inicio: "", duracion: "" })}
          > Añadir descansos </button>
          {descansosArray.length > 0 && (
            <FormFieldset css={styles.descansosParent} legend="Descansos">
              {descansosArray.map((descanso, index) => {
                return (
                  <FormFieldset css={styles.descansoInfo} legend={`Descanso ${index + 1}`}>
                    <span className={styles.eliminarDescanso} onClick={() => unsetField(`descansos.${index}`)}>
                      Eliminar
                    </span>
                    <div>
                      <span>Inicio:</span>
                      <input
                        className="text-sm w-min ms-1"
                        type="time"
                        name={`descansos.${index}.inicio`}
                        defaultValue={descanso.inicio}
                      />
                    </div>
                    <div>
                      <span>Duración:</span>
                      <input
                        className="text-sm ms-1"
                        type="number"
                        name={`descansos.${index}.duracion`}
                        defaultValue={descanso.duracion}
                      />
                      <span className="text-gray-500">min</span>
                    </div>
                  </FormFieldset>
                );
              })}
            </FormFieldset>
          )}
        </div>

        <FormFieldset css="flex-col gap-5" legend="Curso académico">
          <div>
            <span>Fecha inicio curso:</span>
            <input type="date" name="inicioCurso" />
          </div>
          <div>
            <span>Fecha fin curso:</span>
            <input type="date" name="finCurso" />
          </div>
        </FormFieldset>

        {/* <div className="flex flex-col items-center justify-center w-full">
          <button type="button" className="self-end bg-red-400 mi-boton hover:bg-red-500"
            onClick={() => addField("festivos", { fecha: '' })}
          > Añadir festivos </button>
          {festivosArray.length > 0 && (
            <FormFieldset css={styles.descansosParent} legend='Festivos'>
              {festivosArray.map((f, index) => (
                <div className={styles.descansoInfo}>
                  <span>Indica un nombre:</span>
                  <input type="text" name={`festivos.${index}.nombre`} />
                  <span>Elige una fecha:</span>
                  <input type="date" name={`festivos.${index}.fecha`} />
                </div>
              ))}
            </FormFieldset>
          )}
        </div> */}
      </form>
    </main >
  );
}
