import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
export const SuccessMessage = ({ text }) => {
  return (
    <div className="success-message">
        <BsPatchCheckFill />
        <h1>{text}</h1>
    </div>
  );
};
