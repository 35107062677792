import React from "react";
import { MdError } from "react-icons/md";

export const ErrorMessage = ({ text }) => {
  return (
    <div className="error-message">
      <MdError />
      <h1>{text}</h1>
    </div>
  );
};
