import React, { useState } from 'react'
import { UserTable } from './UserTable';
import { AddUserForm } from './AddUserForm';
import { EditUserForm } from './EditUserForm';
import { deleteUser, updateUser, createUser } from '../../../fetching/AdminAPI';
import { useContext } from 'react';
import { MessageContext } from '../../../contexts/MessageContext';
import styles from './users-manager.module.css'
import { TituloPagina } from '../../TituloPagina';
import { Spinner } from '../../Spinner';

export const UsersManager = () => {
    const { setError, setSuccess } = useContext(MessageContext)
    const [editing, setEditing] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [response, setResponse] = useState(null)
    const [loading, setLoading] = useState(false)


    const addUser = (body) => {
        setLoading(true)
        createUser(JSON.stringify(body))
            .then(res => {
                setResponse(res)
                setSuccess('Usuario insertado correctamente.')
                setTimeout(() => setSuccess(null), 5000)
            })
            .catch(error => {
                setError(error.response.data.message)
                setTimeout(() => setError(null), 5000)
            })
            .finally(() => setLoading(false))
    }

    const eliminarUsuario = (id) => {
        deleteUser(id)
            .then(res => {
                setResponse(res)
                setSuccess('Usuario eliminado correctamente.')
                setTimeout(() => setSuccess(null), 5000)
            })
            .catch(error => {
                setError(error.response.data.message)
                setTimeout(() => setError(null), 5000)
            })
    }

    const editRow = (user) => {
        setEditing(true);
        setCurrentUser(user);
    }

    const actualizarUsuario = (id, values) => {
        setLoading(true)
        updateUser(id, values)
            .then(res => {
                setResponse(res);
                setSuccess('Usuario actualizado correctamente.')
                setTimeout(() => setSuccess(null), 5000)
            })
            .catch(error => {
                setError(error.response.data.message)
                setTimeout(() => setError(null), 5000)
            })
            .finally(() => setLoading(false))
    }

    return (
        <main id='user-manager' className='p-[.5rem]'>
            <TituloPagina texto={'Gestionar usuarios'} />
            <main className='px-5 py-2 flex items-start gap-4'>
                <div className="w-2/4 flex-col-center">
                    {editing ? (<>
                        <h2 className={styles.h2}>Editar usuario</h2>
                        {loading ? <Spinner h={80} w={80} /> : (
                            <EditUserForm
                                editing={editing}
                                setEditing={setEditing}
                                user={currentUser}
                                updateUser={actualizarUsuario}
                            />)}

                    </>) : (<>
                        <h2 className={styles.h2}>Crear usuarios</h2>
                        {loading ? <Spinner h={80} w={80} /> : (
                            <AddUserForm addUser={addUser} />)}
                    </>)}
                </div>

                <div className="w-2/4 flex-col-center">
                    <h2 className={styles.h2}>Lista de usuarios</h2>
                    <UserTable trigger={response} deleteUser={eliminarUsuario} editUser={editRow} />
                </div>
            </main>
        </main >
    )
}

