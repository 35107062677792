import React, { useEffect, useMemo, useRef, useState } from 'react'
import { SearchBar } from '../../SearchBar';
import styles from './users-manager.module.css'
import { Spinner } from '../../Spinner';
import { getUsers, setActiveUser } from '../../../fetching/AdminAPI';
import uuid from "react-uuid";
import ActiveSwitch from '../../ActiveSwitch';

export const UserTable = ({ trigger, deleteUser, editUser }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null);
    const [data, setData] = useState(null)
    const [array, setArray] = useState({});

    const fetchData = () => {
        setLoading(true);
        getUsers()
            .then(data => {
                setData(data)
            })
            .catch(err => {
                setError(err.response.data.message)
                setTimeout(() => setError(null), [5000])
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData()
    }, [trigger])

    const activeUser = (id) => {

        setActiveUser(id, !array[id])
            .then(res => array[id] = !array[id])
            .catch(err => {
                alert('Ha ocurrido un error')
            })
            .finally(() => {
                fetchData()
            })
    }

    const [query, setQuery] = useState('');
    let filtered = useMemo(() => {
        if (!data) return [];
        return data.filter(item => {
            return (item.name.toLowerCase().includes(query.toLowerCase())) || (item.username.toLowerCase().includes(query.toLowerCase()))
        })
    }, [query, data])

    if (error)
        return <h1>Could not fetch users data</h1>

    return (
        <div className={styles.userTable}>
            <SearchBar query={query} setQuery={setQuery} size={12} />
            <div className='grid grid-cols-5 items-center justify-around gap-2 border-b border-slate-400 p-1 font-bold'>
                <h1>Nombre</h1>
                <h1>Usuario </h1>
                <h1>Activo</h1>
            </div>
            {loading ? <Spinner h={50} w={50} /> : (
                <>
                    {filtered && filtered.map(u => {
                        array[u.id] = u.activo;
                        return (
                            <UserRow user={u} activeUser={activeUser} deleteUser={deleteUser} editUser={editUser} />
                        )
                    })}
                </>
            )}

        </div>
    )
}

function UserRow({ user: u, activeUser, deleteUser, editUser }) {
    return (
        <div className={styles.userRow} key={uuid()}>
            <h1>{u.name}</h1>
            <h1>{u.username}</h1>

            <ActiveSwitch model={u} handleActive={activeUser} />

            <button className='mi-boton-2 hover:bg-cyan-200'
                onClick={() => editUser(u)}
            >Editar</button>
            <button className='mi-boton-2 hover:bg-red-200'
                onClick={() => {
                    if (window.confirm('¿Eliminar definitivamente este usuario y sus reservas?'))
                        deleteUser(u.id);
                }
                }>Eliminar</button>
        </div>
    );
}

