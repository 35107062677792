import { DateTime } from "luxon";

function EmptyCell({ obj, handleClick }) {
    const now = DateTime.local();
    const end = DateTime.fromSQL(`${obj.fecha} ${obj.hora_inicio}`)

    return (
        <div className={(now > end ? '' : 'empty-cell') + ' cell cursor-pointer'} onMouseDown={(e) => handleClick(e, obj)}>
        </div>
    );
}

export default EmptyCell;