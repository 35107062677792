import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL + "/admin";

const API = axios.create({
  baseURL,
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
});

API.defaults.withCredentials = true;

export const festivos = () => API.get("/festivos").then(res => res.data);
export const createFestivo = (body) => API.post('/festivos', body);
export const deleteFestivo = (fecha) => API.delete('/festivos/' + fecha);

export const getSpaces = () => API.get('/spaces/all').then(res => res.data);
export const createSpace = (nombre) => API.post('/spaces', nombre);
export const activeSpace = (id, active) => API.put('/spaces/active/' + id, JSON.stringify({ active }));

export const getConfig = () => API.get("/config").then((res) => res.data);
export const createHorario = (body) => API.post("/config", body);
export const deleteConfig = () => API.delete("/config");

export const getUsers = () => API.get('/users').then(res => res.data);
export const deleteUser = (id) => API.delete('/users/' + id);
export const updateUser = (id, body) => API.put('users/' + id, body);
export const createUser = (body) => API.post('/users', body);
export const setActiveUser = (id, active) => API.put("/users/active/" + id, JSON.stringify({ active }))


export const getHorario = (am) =>
  API.get(`/calendar/horario?am=${am}`).then((res) => res.data);
export const getBookingInfo = (page, spaceId, am) =>
  API.get(`/calendar?page=${page}&space=${spaceId}&am=${am}`).then((res) => res.data);
export const createReserva = (body) => API.post("/calendar", body);
export const getReserva = (id, fecha, sesion) =>
  API.get(`/calendar/${id}?fecha=${fecha}&sesion=${sesion}`).then((res) => res.data);
export const deleteReserva = (id, user) => API.delete("/calendar/" + id, { data: { user } });
export const updateReserva = (id, body) => API.put("/calendar/" + id, body);

