import React, { useEffect, useState } from "react";
import { IoMdInformationCircle } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineClockCircle } from "react-icons/ai";
import { FaTrashCan } from "react-icons/fa6";
import { useForm } from "@felte/react";
import { deleteReserva, getReserva, updateReserva } from "../../fetching/API";
import { useContext } from "react";
import { MessageContext } from "../../contexts/MessageContext";
import { SelectHourOptions } from "../calendar/SelectHourOptions";
import styles from './modalForm.module.css'
import CloseButton from "../CloseButton";
import { Spinner } from "../Spinner";
import { FormularioParent } from "./FormularioParent";
const { DateTime } = require("luxon");

export const FormularioEditarReserva = ({ cellInfo, setIsEditingReserva, data, fetchData, }) => {

  const { setError, setSuccess } = useContext(MessageContext);
  const [reservaInfo, setReservaInfo] = useState('');
  const [loading, setLoading] = useState(true);

  const { form: editForm } = useForm({
  });

  useEffect(() => {
    getReserva(cellInfo.id, cellInfo.fecha)
      .then(i => {
        setReservaInfo(i);
      })
      .catch(error => {
        setError('Ocurrió algo en el servidor')
      })
      .finally(setLoading(false))
  }, [])

  return (
    <FormularioParent>
      {loading ? <Spinner h={80} w={80} /> :
        (
          <>
            <CloseButton handleClick={setIsEditingReserva} target={styles.formulario} />
            <div className="header">
              <h1 className="text-orange-400 inline">
                {DateTime.fromSQL(
                  cellInfo.fecha + " " + reservaInfo.hora_inicio
                ).toLocaleString({
                  weekday: "short",
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}{" "}
                /{" "}
                {DateTime.fromSQL(
                  cellInfo.fecha + " " + reservaInfo.hora_fin
                ).toLocaleString({
                  weekday: "short",
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </h1>
              <small className="flex items-center gap-2">
                <FontAwesomeIcon icon={faDoorOpen} />
                {reservaInfo.espacio}
              </small>
            </div>

            <form ref={editForm}
              onSubmit={(e) => {
                e.preventDefault();
                const formData = new FormData(e.target);
                updateReserva(cellInfo.id, formData)
                  .then(res => {
                    setSuccess(res.data.message)
                    fetchData()
                    setIsEditingReserva(false)
                    setTimeout(() => {
                      setSuccess(null);
                    }, 5000)
                  })
                  .catch(err => {
                    setError(err.response.data)
                    setTimeout(setError(null), 5000);
                  })
              }}
            >
              <button type="button" className="mi-boton w-full bg-red-600 hover:bg-red-700"
                onClick={(e) => {
                  if (window.confirm("Estás seguro de eliminar la reserva")) {
                    deleteReserva(cellInfo.id)
                      .then((res) => {
                        fetchData();
                        setIsEditingReserva(false);
                        setSuccess("Se ha eliminado la reserva correctamente.");
                        setTimeout(() => {
                          setSuccess(null);
                        }, 5000);
                      })
                      .catch((err) => {
                        setError(err.response.message);
                        setTimeout(() => {
                          setError(null);
                        }, 5000);
                      });
                  }
                }}
              >Eliminar reserva <FaTrashCan style={{ display: "inline" }} /></button>
              <div className="flex justify-around items-end">
                <div className="flex gap-4 items-center">
                  <AiOutlineClockCircle />
                  <span className="self-end">Desde:</span>
                  <input
                    type="time"
                    value={DateTime.fromSQL(reservaInfo.hora_inicio).toFormat('HH:mm')}
                  />
                </div>
                <div className="flex items-end gap-4">
                  <span>Hasta:</span>
                  <SelectHourOptions data={data} cellInfo={cellInfo} />
                </div>
              </div>
              <span className="flex items-center gap-2">
                <IoMdInformationCircle />
                Motivo de la reserva:
              </span>
              <textarea
                required
                minLength={30}
                name="descripcion"
                id=""
                cols="20"
                rows="3"
                defaultValue={reservaInfo.descripcion}
              ></textarea>
              <button className="mi-boton guardar">Editar y guardar</button>
            </form>
          </>)}
    </FormularioParent>
  );
};
