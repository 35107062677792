import React, { useState } from 'react';
import SpaceContainer from './SpaceContainer';
import { Horario } from './Horario';
import CalendarBody from './CalendarBody';
import { AmSelector } from './AmSelector';
import AdminCalendarBody from '../admin/AdminCalendarBody';

function Calendar({ isAdmin }) {
    const [space, setSpace] = useState(null)
    const [am, setAm] = useState('am')

    return (
        <main className='bg-slate-50 p-1 rounded-t-xl w-11/12 mx-auto my-8 rounded-sm relative flex-col-center'>
            <SpaceContainer setSpace={setSpace} />
            <div className={(!!space ? 'p-1 pt-4' : '') + ' calendar'}>
                {!!space && <AmSelector setAm={setAm} space={space} />}
                {!!space && <Horario am={am} />}
                {isAdmin ? <AdminCalendarBody space={space} am={am} /> : <CalendarBody space={space} am={am} />}
            </div>
        </main>
    );
}

export default Calendar;