import React from 'react'
import { FaDoorOpen } from "react-icons/fa";
const color = 'bg-rose-500';

export const AmSelector = ({ setAm, space }) => {
    const changeColor = (e) => {
        if (e.target.tagName === 'H5') {
            const spans = e.target.parentElement.parentElement.children;
            for (const child of spans) {
                child.classList.remove(color)
            }
            e.target.parentElement.classList.add(color)
        }
    }
    return (
        <div id='amSelector'
            onClick={(e) => changeColor(e)}
            className='flex-col-center gap-1 h-auto'>
            <div className='flex items-center gap-1 text-slate-800'>
                <FaDoorOpen color='black' />
                <h1 className='block font-montserrat italic'>{space.nombre}</h1>
            </div>
            <div className='flex items-center w-fit justify-self-center self-center rounded-sm shadow bg-cyan-700'>
                <SpanSelector text={'am'} handleClick={() => setAm('am')} />
                <SpanSelector text={'pm'} handleClick={() => setAm('pm')} />
            </div>
        </div>
    )
}

function SpanSelector({ text, handleClick }) {
    return (
        <span className={(text === 'am' ? color : null) + ' px-2 cursor-pointer rounded-sm'}>
            <h5 onClick={() => handleClick()}>{text}</h5>
        </span>
    );
}
