import { IoMdInformationCircle } from "react-icons/io";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDoorOpen } from "@fortawesome/free-solid-svg-icons";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdDateRange } from "react-icons/md";
import { useContext, useState } from "react";
import { SelectHourOptions } from "../calendar/SelectHourOptions";
import { MessageContext } from '../../contexts/MessageContext'
import { createReserva } from "../../fetching/AdminAPI";
import { UserPicker } from "../admin/UserPicker";
import { useForm } from '@felte/react'
import CloseButton from "../CloseButton";
import { Spinner } from "../Spinner";
import styles from './modalForm.module.css'
import { FormularioParent } from "./FormularioParent";
import { UserContext } from "../../contexts/UserContext";
const { DateTime } = require("luxon");


function AdminCreateReserva({ cellInfo, setIsMakingReserva, space, fetchData, data }) {
  const {user} = useContext(UserContext);
  const [loading, setLoading] = useState(false)
  const { setError, setSuccess } = useContext(MessageContext);
  const [repetirReserva, setRepetirReserva] = useState(false);

  const { form, setData } = useForm({
    onSubmit: (values) => {
      const formData = {
        sesion_inicio: cellInfo.sesion,
        sesion_fin: values.sesion_fin,
        descripcion: values.descripcion,
        espacio_id: space.id,
        fecha: cellInfo.fecha,
        user_id: values.user_id || user.id,
        repetir_reserva: values.repetir_reserva
      };
      if (values['repetir_reserva'])
        formData.fecha_fin = values['fin_repeticion'];
      setLoading(true);
      createReserva(JSON.stringify(formData))
        .then((res) => {
          fetchData();
          setIsMakingReserva(false);
          setSuccess(res.data.message)
          setTimeout(() => setSuccess(null), 5000)
        })
        .catch((error) => {
          if (error.response) {
            // La respuesta fue hecha y el servidor respondió con un código de estado
            setError(error.response.data.message);
          } else if (error.request) {
            // La petición fue hecha pero no se recibió respuesta
            setError('No se ha recibido respuesta del servidor')
          } else {
            // Algo paso al preparar la petición que lanzo un Error
            setError('Error: ' + error.message)
          }
          setTimeout(() => setError(null), 5000)
        })
        .finally(() => setLoading(false))
    }
  })

  return (
    <FormularioParent>
      {loading ? <Spinner h={80} w={80} /> :
        (
          <>
            <CloseButton handleClick={setIsMakingReserva} target={styles.formulario} />
            <div className={styles.header}>
              <h1 className="text-orange-400">
                {DateTime.fromSQL(cellInfo.fecha + " " + cellInfo.hora_inicio).toLocaleString({
                  weekday: "short",
                  month: "short",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </h1>
              <small className="flex items-center gap-2">
                <FontAwesomeIcon icon={faDoorOpen} />
                {space.nombre}
              </small>
            </div>
            <form ref={form} className="flex flex-col" /* onSubmit={(e) => { handleSubmit(e) }} */>
              <div className="flex items-end justify-around">
                <div className="flex items-center gap-4">
                  <AiOutlineClockCircle />
                  <span className="self-end">Desde:</span>
                  <input type="time" value={DateTime.fromSQL(cellInfo.hora_inicio).toFormat('HH:mm')} readOnly />
                </div>
                <div className="flex items-end gap-4">
                  <span>Hasta:</span>
                  <SelectHourOptions data={data} cellInfo={cellInfo} />
                </div>
              </div>
              <UserPicker setData={setData} />
              <div>
                <label htmlFor="repetir-reserva-checkbox" className={repetirReserva ? 'font-bold' : ''}>Repetir reserva</label>
                <input className="ms-2" type="checkbox" name="repetir_reserva" id="repetir-reserva-checkbox" onChange={(e) => setRepetirReserva(prev => !prev)} />
                {repetirReserva &&
                  <div>
                    <div className="flex items-center gap-2">
                      <MdDateRange display={'inline-block'} />
                      <label htmlFor="fin-repetir-fecha">Elige la fecha de fin:</label>
                      <input type="date" name="fin_repeticion" id="fin-repetir-fecha" defaultValue={DateTime.local().toISODate()} />
                    </div>
                  </div>}
              </div>
              <span className="flex items-center gap-2">
                <IoMdInformationCircle />
                Motivo de la reserva:
              </span>
              <textarea
                required
                minLength={15}
                name="descripcion"
                id="descripcion"
                cols="20"
                rows="3"
              ></textarea>
              <button className="guardar mi-boton">Guardar</button>
            </form>
          </>)}
    </FormularioParent>
  );
}
export default AdminCreateReserva;
