import React, { useState, useCallback, useEffect, useRef, useMemo } from "react";
import WeekHeader from "./WeekHeader";
import ButtonWeekPage from "./ButtonWeekPage";
import BookingInfo from "./BookingInfo";
import EmptyCell from "../cells/EmptyCell";
import OccupiedCell from "../cells/OccupiedCell";
import FormularioNewReserva from "../modalForms/FormularioNewReserva";
import uuid from "react-uuid";
import { getBookingInfo } from "../../fetching/API";
import { FormularioEditarReserva } from "../modalForms/FormularioEditarReserva";
import { useForm } from "../../hooks/useForm";
import { useContext } from "react";
import { MessageContext } from "../../contexts/MessageContext";
import { DateTime } from "luxon";
import { FestiveCell } from "../cells/FestiveCell";
import { MyCell } from "../cells/MyCell";

function CalendarBody({ space, am }) {
  const [avanza, setAvanza] = useState(true);
  const [retrocede, setRetrocede] = useState(true);
  const [page, setPage] = useState(0);

  const [data, setData] = useState();
  const { setError } = useContext(MessageContext);
  const [isLoading, setLoading] = useState(false);
  const isFirstRender = useRef(true);

  const now = useMemo(() => DateTime.local(), [])

  const {
    info: newReservaInfo,
    isEditing: isMakingReserva,
    setIsEditing: setIsMakingReserva,
    showForm: showFormNew,
  } = useForm();
  const {
    info: editingReservaInfo,
    isEditing: isEditingReserva,
    setIsEditing: setIsEditingReserva,
    showForm: showFormEdit,
  } = useForm();

  const fetchData = () => {
    setLoading(true);
    getBookingInfo(page, space.id, am)
      .then((body) => {
        setData(body);
        setAvanza(body.avanza);
        setRetrocede(body.retrocede)
      })
      .catch((error) => {
        if (error.response) {
          // La respuesta fue hecha y el servidor respondió con un código de estado
          setError(error.response.data);
        } else if (error.request) {
          // La petición fue hecha pero no se recibió respuesta
          setError('Sin respuesta del servidor');
        } else {
          // Algo paso al preparar la petición que lanzo un Error
          setError('Error', error.message);
        }
        setTimeout(() => {
          setError(null);
        }, 5000);
      })
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    if (!isFirstRender.current) fetchData();
    isFirstRender.current = false;
  }, [page, space, am]);

  const clickEmptyCell = useCallback((e, obj) => showFormNew(obj), [data]);

  const clickEditCell = useCallback((e, obj) => showFormEdit(obj), [data]);

  const putCell = (session) => {
    if (!session.ignore)
      if (session.festivo)
        return <FestiveCell key={uuid()} >{session.nombre}</FestiveCell>
      else
        return session.libre ?
          <EmptyCell obj={session} handleClick={clickEmptyCell} key={uuid()} />
          :
          session.userBook ?
            <MyCell obj={session} handleClick={clickEditCell} />
            :
            <OccupiedCell obj={session} key={uuid()} />
    return null;
  }

  return (
    <>
      {isMakingReserva && (
        <FormularioNewReserva
          cellInfo={newReservaInfo}
          setIsMakingReserva={setIsMakingReserva}
          space={space}
          data={data.info}
          fetchData={fetchData}
        />
      )}
      {isEditingReserva && (
        <FormularioEditarReserva
          cellInfo={editingReservaInfo}
          setIsEditingReserva={setIsEditingReserva}
          space={space}
          data={data.info}
          fetchData={fetchData}
        />
      )}
      {data && (
        <WeekHeader data={data.info}>
          <ButtonWeekPage text="arrow_back" handleClick={() => setPage(page - 1)} key={uuid()} avanza={retrocede} />
          <button onClick={(e) => setPage(0)} className='mi-boton shadow-md font-normal w-28 bg-cyan-500 rounded-sm'>Hoy</button>
          <ButtonWeekPage text="arrow_forward" handleClick={() => setPage(page + 1)} avanza={avanza} key={uuid()} />
        </WeekHeader>
      )}
      <BookingInfo data={data} isLoading={isLoading}>
        {data && data.info.map((day) => (
          <div style={{ gridTemplateRows: `repeat(${day.length}, 1fr)` }} key={uuid()}>
            {day.map((session) => putCell(session))}
          </div>
        ))}
      </BookingInfo>
    </>
  );
}

export default CalendarBody;
