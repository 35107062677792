import React, { useState } from 'react'
import { LiaSearchSolid } from "react-icons/lia";

export const SearchBar = ({ query, setQuery, size }) => {
    return (
        <div className='flex items-center search-bar w-fit justify-self-end'>
            <input type="text" name="user_query" placeholder='Buscar usuarios...' autoComplete='off' defaultValue={query}
                onChange={(e) => { setQuery(e.target.value) }} className='border-slate-600' style={{ width: `${size}rem` }} />
            <LiaSearchSolid />
        </div>
    )
}
