import React from 'react'
import styles from './modalForm.module.css'

export const FormularioParent = ({children}) => {
    /**(!loading ? 'p-2' : '') +  */
    return (
        <div className={styles.formulario + " flex-row-center"}>
            <div className={" relative p-2"}>
                {children}
            </div>
        </div>
    )
}
