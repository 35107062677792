import React from "react";
import uuid from "react-uuid";

export const SelectHourOptions = ({ data, cellInfo, fin }) => {
    console.log(data, cellInfo);

    const getValidHours = () => {
        const day = data[cellInfo.coordX];
        const filter = day.slice(day.findIndex(value => value.sesion === cellInfo.sesion));
        let index = filter.findIndex(
            (value, index) => !value.ignore && !value.libre && index !== 0
        );
        if (index === -1) index = filter.length;
        const array = [];
        let j = 0;
        /* if (!cellInfo.libre) {
            //array.push(cellInfo.staticFin);
            j = 1;
        } else j = 0; */

        for (let i = j; i < index; i++) {
            if (filter[i].sesion === fin) {
                array.push(
                    <option key={uuid()} selected value={filter[i].sesion}>{filter[i].hora_fin.slice(0, -3)}</option>
                );
            } else {
                array.push(
                    <option key={uuid()} value={filter[i].sesion}>{filter[i].hora_fin.slice(0, -3)}</option>
                );
            }
        }
        return array;
    };

    return (
        <select name="sesion_fin" id="formulario-edit-reserva-select-hora-fin">
            {getValidHours()}
        </select>
    );
};
