import Calendar from '../components/calendar/Calendar'
function Dashboard({user}) {
    return (
        <>
            <h1 className='text-2xl my-4 mx-4'>Dashboard de {user.username}</h1>
            <Calendar isAdmin={false}/>
        </>
        
     );
}

export default Dashboard;