import React from 'react'
import { useForm } from '@felte/react';
import { MdError } from "react-icons/md";
import styles from './users-manager.module.css'


export const EditUserForm = ({ user, updateUser, setEditing }) => {

    const { form: editForm, errors, reset } = useForm({
        onSubmit: (values) => {
            delete values.password_confirmation;
            updateUser(user.id, values);
        },
        validate: (values) => {
            const errors = {};
            if (!values.name || values.name.length < 5)
                errors.name = 'El nombre completo debe estar compuesto de 5 carácteres mínimo'
            if (!values.username || values.username.length < 5)
                errors.username = 'El nombre de usuario debe estar compuesto de 5 carácteres mínimo'
            if (!values.password)
                errors.password = 'La contraseña es obligatoria.'
            if (values.password !== values.password_confirmation)
                errors.password = 'Las contraseñas deben coincidir.'

            return errors;
        }
    })

    return (
        <form ref={editForm} className={styles.formulario}>
            <div>
                <label >Nombre completo:</label>
                <input type="text" name="name" id="" defaultValue={user.name} />
                {errors('name') && <span className="text-sm text-red-500"><MdError style={{ display: 'inline-block' }} /> {errors('name')}</span>}
            </div>
            <div>
                <label>Nombre de usuario:</label>
                <input type="text" name="username" id="" defaultValue={user.username} />
                {errors('username') && <span className="text-sm text-red-500"> <MdError style={{ display: 'inline-block' }} /> {errors('username')}</span>}
            </div>
            <div>
                <label>Email:</label>
                <input type="email" name="email" id="" defaultValue={user.email} />
                {errors('username') && <span className="text-sm text-red-500"> <MdError style={{ display: 'inline-block' }} /> {errors('username')}</span>}
            </div>
            <div>
                <label>Contraseña:</label>
                <input type="text" name="password" id="" />
                {errors('password') && <span className="text-sm text-red-500"><MdError style={{ display: 'inline-block' }} /> {errors('password')}</span>}
            </div>
            <div>
                <label>Confirmar contraseña:</label>
                <input type="text" name="password_confirmation" id="" />
            </div>
            <div>
                <label>Asignar rol:</label>
                <select name="admin" id="" value='0'>
                    <option value="0">Usuario</option>
                    <option value="1">Administrador</option>
                </select>
            </div>
            <button type='button' onClick={() => setEditing(false)} className='bg-red-500 mi-boton self-end'>Cancelar</button>
            <button type='submit' className='mi-boton self-end guardar'>Editar y guardar</button>
        </form>)
}
