import React, { useEffect, useState } from 'react'
import { createFestivo, deleteFestivo, festivos } from '../../../fetching/AdminAPI';
import { useContext } from 'react';
import { MessageContext } from '../../../contexts/MessageContext';
import { useForm } from '@felte/react';
import { TituloPagina } from '../../TituloPagina';
import { Spinner } from '../../Spinner';

export const Festivos = () => {
    const { setError, setSuccess } = useContext(MessageContext);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true)

    const { form } = useForm({
        onSubmit: (values) => {
            setLoading(true)
            createFestivo(values)
                .then(res => {
                    fetchData()
                    setSuccess('Añadido a la lista de festivos')
                    setTimeout(() => setSuccess(null), 5000)
                })
                .catch(err => {
                    setError(err.response.data.message)
                    setTimeout(() => setError(null), 5000)
                })
                .finally(() => setLoading(false))
        }
    })

    const fetchData = () => {
        festivos()
            .then(data => setData(data))
            .catch((err) => {
                setError(err.response.data.message)
                setTimeout(() => setError(null), 5000)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        fetchData();
    }, [])

    const deleteF = (fecha) => {
        console.log(fecha);
        deleteFestivo(fecha)
            .then(res => {
                fetchData()
                setSuccess('Festivo eliminado')
                setTimeout(() => setSuccess(null), 5000)
            })
            .catch(err => {
                setError(err.response.data.message)
                setTimeout(() => setError(null), 5000)
            })
    }

    return (
        <main className='p-[.5rem]'>
            <TituloPagina texto={'Días festivos'} />
            <main className='flex-col-center gap-2'>
                <form ref={form} className='flex flex-col w-2/4'>
                    <div className='flex flex-col gap-1'>
                        <label htmlFor="nombre" className='text-slate-500'>Nombre del festivo:</label>
                        <input type="text" name="nombre" id="nombre" required />
                    </div>
                    <div className='flex flex-col gap-1'>
                        <label htmlFor="fecha" className='text-slate-500'>Fecha:</label>
                        <input type="date" name="fecha" id="fecha" required />
                    </div>
                    <button type="submit" className='mi-boton bg-emerald-500 hover:bg-emerald-600 self-end'>Guardar</button>
                </form>

                <div className='w-2/4'>
                    <h4 className='text-2xl'>Listado de festivos:</h4>
                    <div className='flex flex-col gap-2 relative'>
                        {loading ? <Spinner h={80} w={80} /> : (
                            data.map(f => (
                                <div className='flex gap-1 py-1 px-2 items-center justify-between text-white rounded-sm bg-indigo-900'>
                                    <p>"{f.nombre}"</p>
                                    <p className='font-bold'>{f.fecha}</p>
                                    <button className='mi-boton-2 hover:bg-red-200' onClick={() => { deleteF(f.fecha) }}>Eliminar</button>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </main>
        </main>
    )
}
