import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;

const API = axios.create({
  baseURL,
  withCredentials: true,
  credentials: 'include',
  headers: {
    "Content-Type": "application/json",
  },
});

export const login = (values)=>API.post('/auth/login', values).then(res=>res.data)

export const getUserInfo = () => API.get('/me').then(res => res.data);
export const deleteCookie = () => API.delete('/cookie');

export const getSpaces = () => API.get('/spaces').then(res => res.data);
export const getHorario = (am) =>
  API.get(`/calendar/horario?am=${am}`).then((res) => res.data);

export const getBookingInfo = (page, spaceId, am) =>
  API.get(`/calendar?page=${page}&space=${spaceId}&am=${am}`).then((res) => res.data);
  export const getReserva = (id, fecha) =>
    API.get(`/calendar/${id}?fecha=${fecha}`).then((res) => res.data);
export const createReserva = (body) => API.post("/calendar", body);
export const updateReserva = (id, body) => API.put("/calendar/" + id, body);
export const deleteReserva = (id) => API.delete("/calendar/" + id);

