function OccupiedCell({ obj }) {
  const style = { gridRow: `span ${obj.span || 1}` };
  return (
    <div className="cell bg-gray-400 cursor-not-allowed" style={style}>
        <p className="text-sm">Reservado ({obj.name})</p>
    </div>
  );
}

export default OccupiedCell;
