function ActiveSwitch({ model, handleActive }) {
    return (
        <label className='switch'>
            {model.activo ?
                <input type="checkbox" defaultChecked onChange={(e) => handleActive(model.id, e.target.value)} />
                : <input type="checkbox" onChange={(e) => handleActive(model.id, e.target.value)} />}
            <span className='slider round'></span>
        </label>
    );
}

export default ActiveSwitch;