import { DateTime } from 'luxon';
import React from 'react';
import uuid from 'react-uuid';

const DayHeader = ({ info }) => {
    const fecha = DateTime.fromSQL(info[0].fecha);
    const dateToString = fecha.setLocale("es").toFormat("ccc, d LLL");

    const today = DateTime.local().toISODate() === fecha.toISODate();

    const [upperInfo, lowerInfo] = dateToString.split(',');
    const css = 'rounded-[1px] flex flex-col justify-center items-center shadow-md p-1 mx-[1.5px] w-full';
    return (
        <div
            className={(today ? 'bg-cyan-500 ' : 'bg-cyan-800 ') + css} key={uuid()}>
            <span className='uppercase'>{upperInfo}</span>
            <small>{lowerInfo}</small>
        </div>
    );
}

function customComparison(prevProp, nextProp) {
    return (nextProp.info === prevProp.info && nextProp.today === prevProp.today) || nextProp.info.length === 0;
}

export const MemoizedHeader = React.memo(DayHeader, customComparison);