import React, { useEffect, useMemo, useState } from "react";
import { getHorario } from "../../fetching/API";
import { DateTime } from "luxon";

export function Horario({ am }) {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    getHorario(am)
      .then((data) => {
        setData(data)
      })
      .catch((err) => setError(err));
    setLoading(false);
  }, [am]);

  const now = useMemo(() => DateTime.local().hour, []);

  if (loading) return <h1 className="text-black">Loading...</h1>;

  if (error) return <h1>{error.message}</h1>;

  if (data) {
    return (
      <div
        id="horario"
        className="col-start-1 row-start-2 grid grid-cols-1 gap-[1px] rounded-s-sm border-y-[1px] border-s-[1px] bg-cyan-700 border-cyan-900 p-1 text-sm w-full h-full"
        style={{ gridTemplateRows: `repeat(${data.length}, 1fr)` }}
      >
        {data.map((intervalo) => {
          const isHour = (DateTime.fromISO(intervalo.hora_inicio).hour <= now) && (now < DateTime.fromISO(intervalo.hora_fin).hour)
          return (
            <div
              key={`sesion-${intervalo.hora_inicio}`}
              className={(isHour ? 'bg-cyan-500' : 'bg-cyan-800') + " text-center flex-row-center shadow-lg leading-10 min-h-12"}
            >
              <span>
                {intervalo.hora_inicio.substring(0, 5) + "-" + intervalo.hora_fin.substring(0, 5)}</span>
            </div>
          );
        })}
      </div>
    );
  }
}
