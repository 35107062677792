import { IoHomeSharp } from "react-icons/io5";
import { useContext } from "react";
import { UserContext } from '../contexts/UserContext';
import { Link } from "react-router-dom";

function NavGeneral({ logOut }) {
    const { user } = useContext(UserContext);
    return (
        <nav className="nav-general">
            <div className="nav-image-parent h-full">
                <img src="https://portal.edu.gva.es/salvadorasancho/wp-content/uploads/sites/1924/2023/01/gv_conselleria_educacio_cmyk_val.png"
                    alt="gva_logo"
                    className="w-full h-full"
                />
            </div>
            <span>
                {user && user.admin ?
                    <Link to={'/admin'}>
                        <div className="flex items-center gap-2">Página de inicio
                            <span><IoHomeSharp /> </span>
                        </div>
                    </Link>
                    : ''}
            </span>
            <button onClick={() => logOut()} className="bg-red-500 mi-boton hover:bg-red-600 justify-self-end self-end">Salir</button>
        </nav >
    );
}

export default NavGeneral;