import uuid from 'react-uuid';
import { MemoizedHeader } from './DayHeader';
import { DateTime } from 'luxon';

function WeekHeader({ data, children }) {
    const today = DateTime.local();
    return (
        <div className='flex flex-col justify-center w-full col-start-2 gap-1'>
            <div className='flex justify-center gap-3'>
                {children}
            </div>
            <div className={'day-header-parent rounded-t-sm border-x-[1px] border-t-[1px] border-cyan-900 p-1 bg-cyan-700 flex items-center justify-around'}>
                {data && data.map((day, index) => {
                    return <MemoizedHeader info={day} key={'dayHeader_'+index}/>
                }
                )}
            </div>
        </div>
    );
}

export default WeekHeader;