import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthenticationPage from "./components/authentication/AuthenticationPage";
import { FormularioConfiguracion } from "./components/admin/FormularioConfig/FormularioConfiguracion";
import Dashboard from "./pages/Dashboard";
import RequireLogin from "./components/RequireLogin";
import NavGeneral from "./components/NavGeneral";
import AdminPage from "./pages/AdminPage";
import { UsersManager } from "./components/admin/UsersManager/UsersManager";
import { UserContext } from "./contexts/UserContext";
import { MessageContext } from "./contexts/MessageContext";
import { ErrorMessage } from "./components/mensajes/ErrorMessage";
import { SuccessMessage } from "./components/mensajes/SuccessMessage";
import { getUserInfo, deleteCookie } from "./fetching/API";
import { Oval } from "react-loader-spinner";
import Calendar from "./components/calendar/Calendar";
import { Festivos } from "./components/admin/Festivos/Festivos";
import { Aulas } from "./components/admin/Aulas/Aulas";
import { Footer } from "./components/Footer";

function App() {
    const [userStatus, serUserStatus] = useState('LOADING');

    const [user, setUser] = useState(null);
    const [isAuthenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        serUserStatus('LOADING');
        getUserInfo()
            .then(data => {
                setUser(data)
                setAuthenticated(true)
                serUserStatus('SUCCESS')
            })
            .catch(error => {
                setUser(null)
                setAuthenticated(false)
                serUserStatus('ERROR');
            })
    }, [])

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const login = (userData) => {
        setUser(userData);
        setAuthenticated(true);
    }

    const logOut = () => {
        if (window.confirm('¿Desea cerrar la sesión?')) {
            deleteCookie()
                .then(res => {
                    setUser(null);
                    setAuthenticated(false);
                })
                .catch(error => {
                    setError('Error inesperado.')
                })
        }
    }

    /* if (userStatus === 'ERROR') {
        return <Navigate to={'/auth'} />
    } */

    if (userStatus === 'LOADING') {
        return <Oval
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{ position: 'absolute', left: '50%', top: '40%' }}
            wrapperClass=""
        />
    }
    else
        return (
            <UserContext.Provider value={{ user, isAuthenticated, login, logOut }}>
                <MessageContext.Provider value={{ error, setError, success, setSuccess }}>
                    <Router>
                        <>
                            {error && <ErrorMessage text={error} />}
                            {success && <SuccessMessage text={success} />}
                            <NavGeneral logOut={logOut} />
                            <Routes>
                                <Route element={<RequireLogin isAllowed={isAuthenticated && !user.admin} redirectTo={user ? '/admin' : '/auth'} />}>
                                    <Route index element={<Dashboard user={user} />} />
                                </Route>
                                <Route element={<RequireLogin isAllowed={!isAuthenticated} redirectTo='/' />}>
                                    <Route exact path="/auth" element={<AuthenticationPage />} />
                                </Route>
                                <Route path="/admin/*" element={<RequireLogin isAllowed={isAuthenticated && user.admin} redirectTo={user ? '/' : '/auth'} />}>
                                    <Route index element={<AdminPage />} />
                                    <Route exact path="config" element={<FormularioConfiguracion />} />
                                    <Route exact path="users" element={<UsersManager />} />
                                    <Route exact path="festivos" element={<Festivos />} />
                                    <Route exact path="aulas" element={<Aulas />} />
                                    <Route exact path="reservas" element={<Calendar isAdmin={true} />} />
                                </Route>
                            </Routes>
                        </>
                    </Router>
                    <Footer />
                </MessageContext.Provider>
            </UserContext.Provider>
        )
}

export default App;
