import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from '@felte/react';
import styles from './auth-page.module.css'
import { UserContext } from '../../contexts/UserContext';
import { Oval } from "react-loader-spinner";
import { MessageContext } from '../../contexts/MessageContext';
import { login as postLogin } from "../../fetching/API";
import { Spinner } from "../Spinner";


function AuthenticationPage() {

    const { setError } = useContext(MessageContext);
    const { login } = useContext(UserContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const { form: formLogin, errors: loginErrors } = useForm({

        onSubmit: async (values) => {
            setLoading(true)
            postLogin(values)
                .then(data => {
                    const payload = data;
                    login(payload);
                    if (payload.admin)
                        navigate('/admin/')
                    else
                        navigate('/');
                })
                .catch(error => {
                    setError(error.response.data.message);
                    setTimeout(() => setError(null), [5000])
                })
                .finally(() => setLoading(false))
        },
        validate: (values) => {
            const errors = {};
            if (!values.username)
                errors.username = 'No puedes dejar este campo vacío';

            if (!values.password)
                errors.password = 'No puedes dejar este campo vacío';

            return errors;
        }
    });

    return (
        <div className={styles.content}>
            {loading && <Spinner h={80} w={80}/>}
            <div className="w-2/4 flex-col-center overflow-hidden text-[1.2rem] mt-[10vh]">
                <div className="w-fit rounded">
                    <div className={styles.pestañaDefault + " translate-y-3 bg-red-500"}>
                        <h1>Inicio de sesión</h1>
                    </div>
                </div>
                <div className={styles.loginForm + " flex-row-center"}>
                    <form className="flex-col-center" ref={formLogin} method="post">
                        <div className="relative flex flex-col">
                            <input type="text" name="username" defaultValue='' />
                            <label className={styles.inputLabel}>Nombre de usuario</label>
                            {loginErrors('username') && <span className="text-sm text-red-500">{loginErrors('username')}</span>}
                        </div>
                        <div className="relative flex flex-col">
                            <input type="password" name="password" />
                            <label className={styles.inputLabel}>Contraseña</label>
                            {loginErrors('password') && <span className="text-sm text-red-500">{loginErrors('password')}</span>}
                        </div>
                        <button type="submit" className="mi-boton bg-red-500 hover:bg-red-600 text-sm self-end">Iniciar sesión</button>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default AuthenticationPage;