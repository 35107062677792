import React, { useEffect, useMemo, useState, useContext } from 'react'
import { FaUser } from 'react-icons/fa'
import { SearchBar } from '../SearchBar'
import uuid from 'react-uuid'
import { getUsers } from '../../fetching/AdminAPI';
import { MessageContext } from '../../contexts/MessageContext';
import { Spinner } from '../Spinner';
import { UserContext } from '../../contexts/UserContext';


export const UserPicker = ({ setData }) => {
  const [loading, setLoading] = useState(true);
  const { setError } = useContext(MessageContext);
  const [allUsers, setAllUsers] = useState(null);
  const { user:u } = useContext(UserContext);
  const [user, setUser] = useState(u)
  const [query, setQuery] = useState('');


  const filtered = useMemo(() => {
    if (query)
      return allUsers.filter(item => {
        return item.name.toLowerCase().includes(query.toLowerCase())
      })
  }, [query, allUsers])

  useEffect(() => {
    getUsers()
      .then(data => {
        setAllUsers(data)
      })
      .catch(err => {
        setError(err.response.data.message)
        setTimeout(() => setError(null), [5000])
      })
      .finally(() => setLoading(false))
  }, [])


  return (
    <div className="relative flex items-start gap-2 p-2 border border-orange-300 bg-slate-100 min-h-9">
      {loading ? <Spinner w={30} h={30} /> :
        (
          <>
            <div className="flex flex-col w-full gap-1">
              <div className="">
                <p>Usuario de la reserva:</p>
              </div>
              <div className="flex items-center w-full gap-1">
                <FaUser />
                <p className="font-bold">{user.name}</p>
              </div>
            </div>
            <div className="relative self-end">
              <div className="self-end w-fit">
                <SearchBar query={query} setQuery={setQuery} size={12} />
              </div>
              <div className="absolute h-auto bg-slate-800 text-white rounded-sm w-[100%] top-[105%]">
                {filtered && filtered.map(item => {
                  return (
                    <div key={uuid()} className="flex items-center gap-2 p-1 my-1 border-b cursor-pointer"
                      onClick={() => { setUser(item); setQuery(null); setData('user_id', item.id) }}>
                      <h3 className="text-base font-bold truncate text-ellipsis">{item.name}</h3>
                      <h4 className="text-sm">{item.username}</h4>
                    </div>
                  )
                })}
              </div>
            </div>
          </>
        )}
    </div >
  )
}
