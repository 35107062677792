import React, { useState, useCallback, useEffect, useRef } from "react";
import WeekHeader from "../calendar/WeekHeader";
import ButtonWeekPage from "../calendar/ButtonWeekPage";
import BookingInfo from "../calendar/BookingInfo";
import EmptyCell from "../cells/EmptyCell";
import uuid from "react-uuid";
import { getBookingInfo } from "../../fetching/AdminAPI";
import { useForm } from "../../hooks/useForm";
import { useContext } from "react";
import { MessageContext } from "../../contexts/MessageContext";
import { FestiveCell } from "../cells/FestiveCell";
import { MyCell } from "../cells/MyCell";
import AdminCreateReserva from "../modalForms/AdminCreateReserva";
import AdminEditReserva from "../modalForms/AdminEditReserva";

function AdminCalendarBody({ space, am }) {
  const [avanza, setAvanza] = useState(null);
  const [retrocede, setRetrocede] = useState(null);
  const [page, setPage] = useState(0);

  const [data, setData] = useState(null);
  const { setError } = useContext(MessageContext);
  const [isLoading, setLoading] = useState(false);
  const isFirstRender = useRef(true);

  const {
    info: newReservaInfo,
    isEditing: isMakingReserva,
    setIsEditing: setIsMakingReserva,
    showForm: showFormNew,
  } = useForm();
  const {
    info: editingReservaInfo,
    isEditing: isEditingReserva,
    setIsEditing: setIsEditingReserva,
    showForm: showFormEdit,
    canEdit: canEdit
  } = useForm();

  const fetchData = () => {
    setLoading(true);
    getBookingInfo(page, space.id, am)
      .then((body) => {
        setData(body);
        setAvanza(body.avanza);
        setRetrocede(body.retrocede)
      })
      .catch((error) => {
        if (error.response) {
          // La respuesta fue hecha y el servidor respondió con un código de estado
          setError(error.response.data.message);
        } else if (error.request) {
          // La petición fue hecha pero no se recibió respuesta
          setError('Sin respuesta del servidor');
        } else {
          // Algo paso al preparar la petición que lanzo un Error
          setError('Error', error.message);
        }
        setTimeout(() => {
          setError(null);
        }, 5000);
      })
      .finally(() => setLoading(false))
  };

  useEffect(() => {
    if (!isFirstRender.current) fetchData();
    isFirstRender.current = false;
  }, [page, space, am]);

  const clickEmptyCell = useCallback((e, obj) => showFormNew(obj), [data]);

  const clickEditCell = useCallback((e, obj) => showFormEdit(obj), [data]);

  return (
    <>
      {isMakingReserva && (
        <AdminCreateReserva
          cellInfo={newReservaInfo}
          setIsMakingReserva={setIsMakingReserva}
          space={space}
          data={data.info}
          fetchData={fetchData}
        />
      )}
      {isEditingReserva && (
        <AdminEditReserva
          cellInfo={editingReservaInfo}
          setIsEditingReserva={setIsEditingReserva}
          space={space}
          data={data.info}
          fetchData={fetchData}
          canEdit={canEdit}
        />
      )}
      {data && (
        <WeekHeader data={data.info}>
          <ButtonWeekPage
            text="arrow_back"
            handleClick={() => setPage(page - 1)}
            key={uuid()}
            avanza={retrocede}
          />
          <button onClick={() => setPage(0)} className='font-normal text-white rounded-sm shadow-md mi-boton w-28 bg-cyan-500'>Hoy</button>
          <ButtonWeekPage
            text="arrow_forward"
            handleClick={() => setPage(page + 1)}
            avanza={avanza}
            key={uuid()}
          />
        </WeekHeader>
      )}
      <BookingInfo data={data} isLoading={isLoading}>
        {data &&
          data.info.map((day) => (
            <div
              style={{ display: "grid", gridTemplateRows: `repeat(${day.length}, 1fr)` }}
              key={uuid()}
            >
              {day.length > 0 && day.map((session) => {
                if (!session.ignore)
                  if (session.festivo)
                    return <FestiveCell key={uuid()} >{session.nombre}</FestiveCell>
                  else
                    return session.libre
                      ?
                      <EmptyCell obj={session} handleClick={clickEmptyCell} key={uuid()} />
                      :
                      <MyCell obj={session} handleClick={clickEditCell} key={uuid()} />

                return null;
              })}
            </div>
          ))}
      </BookingInfo>
    </>
  );
}

export default AdminCalendarBody;
